import React, { useState } from "react";
import { Button, Container, Form, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { BrowserRouter, Redirect } from "react-router-dom";

import Routes from "./Routes";
import { IAuth } from "./shared/interfaces";

const App: React.FC = () => {
  const storageKey = "vitrais-auth";
  const [authData, setAuthData] = useState<IAuth>({
    isAuthenticated: false,
  });

  const doLogout = () => {
    setAuthData({
      ...authData,
      isAuthenticated: false,
    });
    localStorage.removeItem(storageKey);
    return <Redirect to="/login" />;
  };

  return (
    <div className="App">
      <BrowserRouter>
        <header>
          <Navbar bg="dark" variant="dark">
            <Container>
              <Navbar.Brand>Cálculo de Vitrais</Navbar.Brand>
              {authData.isAuthenticated && (
                <>
                  <Navbar.Toggle aria-controls="topbar-nav" />
                  <Navbar.Collapse id="topbar-nav">
                    <Nav className="mr-auto">
                      <LinkContainer to="/projetos">
                        <Nav.Link>Projetos</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/cores">
                        <Nav.Link>Cores</Nav.Link>
                      </LinkContainer>
                    </Nav>
                    <Form inline>
                      <Button variant="outline-danger" onClick={doLogout}>
                        Sair
                      </Button>
                    </Form>
                  </Navbar.Collapse>
                </>
              )}
            </Container>
          </Navbar>
        </header>
        <Container className="p-3">
          <Routes
            authData={authData}
            setAuthData={setAuthData}
            storageKey={storageKey}
          />
        </Container>
      </BrowserRouter>
    </div>
  );
};

export default App;
