import { Crop } from "react-image-crop";

const getCroppedImg = (
  originalImage: string,
  renderedImage: HTMLImageElement,
  crop: Crop
) => {
  return new Promise<HTMLCanvasElement>((resolve) => {
    const originalImg = document.createElement("img");
    originalImg.onload = () => {
      if (crop.width && crop.height) {
        const canvas = document.createElement("canvas");
        const scale = {
          x: originalImg.naturalWidth / renderedImage.width,
          y: originalImg.naturalHeight / renderedImage.height,
        };
        const ctx = canvas.getContext("2d");
        canvas.width = crop.width * scale.x;
        canvas.height = crop.height * scale.y;
        ctx?.drawImage(
          originalImg,
          crop.x! * scale.x,
          crop.y! * scale.y,
          crop.width * scale.x,
          crop.height * scale.y,
          0,
          0,
          canvas.width,
          canvas.height
        );
        resolve(canvas);
      }
    };
    originalImg.src = originalImage;
  });
};

export default getCroppedImg;
