import hexToRgb, { deltaE, rgb2lab } from "../shared/colorUtils";
import { Color, SimpleColor } from "../shared/interfaces";

const processColors = (
  imageData: string,
  defaultColors: Color[],
  sensibility: number = 0.005
) => {
  return new Promise<Color[]>((resolve) => {
    const image = document.createElement("img");
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) resolve([]);
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      ctx!.drawImage(image, 0, 0, image.width, image.height);

      const pixelArray = Array.from(
        ctx!.getImageData(0, 0, image.width, image.height).data
      );
      const rgbData: { [key: string]: SimpleColor } = {};
      let totalPixels = 0;
      for (let i = 0; i < pixelArray.length; i += 4) {
        const pixel = [pixelArray[i], pixelArray[i + 1], pixelArray[i + 2]];
        if (
          deltaE(rgb2lab(pixel), [
            100,
            0.00526049995830391,
            -0.010408184525267927,
          ]) >= 10
        ) {
          if (rgbData[pixel.join("")] === undefined) {
            rgbData[pixel.join("")] = {
              rgb: pixel,
              count: 0,
            };
          } else {
            totalPixels++;
            rgbData[pixel.join("")].count++;
          }
        }
      }
      const filteredColors: { [key: string]: Color } = {};
      Object.values(rgbData).forEach((color1) => {
        color1.lab = rgb2lab(color1.rgb);
        let distance = Number.POSITIVE_INFINITY;
        for (let i in defaultColors) {
          const lab = rgb2lab(hexToRgb(defaultColors[i].hex));
          const defDistance = deltaE(color1.lab, lab);
          if (defDistance < distance) {
            color1.closest = defaultColors[i];
            distance = defDistance;
          }
        }
        if (color1.closest) {
          if (filteredColors[color1.closest.hex] === undefined) {
            filteredColors[color1.closest.hex] = color1.closest;
            filteredColors[color1.closest.hex].count = 0;
          }
          filteredColors[color1.closest.hex].count += color1.count;
        }
      });
      resolve(
        Object.values(filteredColors)
          .filter((c) => c.count > totalPixels * sensibility)
          .sort((a, b) => {
            if (a.category > b.category) return -1;
            if (b.category > a.category) return 1;
            return 0;
          })
      );
    };
    image.src = imageData;
  });
};

export default processColors;
