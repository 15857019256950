import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Color } from "../../shared/interfaces";
import "./styles.css";

interface ColorInfoProps {
  color: Color;
  quantity: number;
  removeCallback: () => void;
}

const ColorInfo = ({
  color,
  quantity,
  removeCallback,
}: ColorInfoProps): JSX.Element => {
  const getColorArea = () => {
    return (color.colorArea || 0).toLocaleString("pt-BR", {
      maximumFractionDigits: 3,
    });
  };

  const getFullColorArea = () => {
    return (color.fullColorArea || 0).toLocaleString("pt-BR", {
      maximumFractionDigits: 3,
    });
  };

  return (
    <>
      <Col xs={12} sm={6} md={4} lg={3}>
        <Card className="item-block mb-3">
          <Card.Body>
            <Row>
              <Col xs={12} className="item-color mb-2">
                <i
                  className="item-color-badge"
                  style={{ backgroundColor: color.hex }}
                ></i>
                <span className="ml-2">
                  <small>{`${getColorArea()}m² x${quantity} → ${getFullColorArea()}m²`}</small>
                </span>
              </Col>
              <Col xs={12} className="item-info">
                <div>
                  <strong>Código:</strong> {color.code}
                </div>
                <div>
                  <strong>Categoria:</strong> {color.category}
                </div>
                {color.ref && (
                  <div>
                    <strong>Referência:</strong> {color.ref}
                  </div>
                )}
                <div>
                  <strong>Cor:</strong> {color.name}
                </div>
                {color.texture && (
                  <div>
                    <strong>Textura:</strong> {color.texture}
                  </div>
                )}
                <div>
                  <strong>Tonalidade:</strong> {color.hue}
                </div>
              </Col>
              <Col data-html2canvas-ignore>
                <Button
                  className="mt-2"
                  variant="outline-danger"
                  size="sm"
                  onClick={removeCallback}
                >
                  remover cor
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default ColorInfo;
