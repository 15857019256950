import { faChevronLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import ReactCrop, { Crop, PercentCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useParams } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import api from "../../shared/api";
import convertPdf from "../../shared/convertPdf";
import getCroppedImg from "../../shared/getCroppedImg";
import trimCanvas from "../../shared/trimCanvas";
import "./styles.css";


const Uploader: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const [imagesList, setImagesList] = useState<HTMLCanvasElement[]>([]);
  const [cropImage, setCropImage] = useState("");
  const [renderCropImage, setRenderCropImage] = useState<HTMLImageElement>(
    document.createElement("img")
  );
  const [cropInfo, setCropInfo] = useState({} as Crop);
  const [loading, setLoading] = useState(false);
  const [trimmedCrop, setTrimmedCrop] = useState<HTMLCanvasElement>();
  const history = useHistory();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setCropImage("");
    setCropInfo({});
    setTrimmedCrop(undefined);
    if (!event.target.files) return;
    const { type } = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      let image = reader.result as string;
      if (type === "application/pdf") {
        image = await convertPdf(reader.result as string);
      }
      setCropImage(image);
      setLoading(false);
    };
    if (type.startsWith("image/")) {
      reader.readAsDataURL(event.target.files[0]);
    } else if (type === "application/pdf") {
      reader.readAsBinaryString(event.target.files[0]);
    }
  };

  const handleImageCrop = async (crop: Crop, percent: PercentCrop) => {
    if (crop.width && crop.height) {
      const croppedImage = await getCroppedImg(
        cropImage,
        renderCropImage,
        crop
      );
      setTrimmedCrop(trimCanvas(croppedImage));
    }
  };

  const canvasToBlob = (canvas: HTMLCanvasElement) => {
    return new Promise<Blob>((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          }
        },
        "image/jpeg",
        100
      );
    });
  };

  const saveImages = async () => {
    setLoading(true);
    const fData = new FormData();
    for (let i in imagesList) {
      const newImage = await canvasToBlob(imagesList[i]);
      fData.append(
        "file",
        newImage,
        `image-${i}.${newImage.type.split("/").pop()}`
      );
    }
    const data = await api.put(`/projects/${id}`, fData, {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    });

    if (data) {
      history.push(`/projetos/${id}`);
    }
  };

  return (
    <>
      <h2>
        Processar Nova Imagem
        <LinkContainer to={`/projetos/${id}`}>
          <Button className="float-right" variant="primary">
            <FontAwesomeIcon icon={faChevronLeft} /> Voltar
          </Button>
        </LinkContainer>
      </h2>
      <hr />
      {loading && <FontAwesomeIcon icon={faSpinner} size="2x" spin />}
      <Form.Group controlId="fileUpload">
        <Form.Label>Selecione o arquivo</Form.Label>
        <Form.Control
          type="file"
          accept="image/*,application/pdf"
          onChange={handleImageChange}
          disabled={loading}
        />
      </Form.Group>
      {cropImage && (
        <div className="mt-3">
          <h3>Selecione a área na imagem para ser utilizada:</h3>
          <ReactCrop
            ruleOfThirds
            className="mt-1"
            src={cropImage}
            crop={cropInfo}
            onImageLoaded={setRenderCropImage}
            onChange={setCropInfo}
            onComplete={handleImageCrop}
          />
          {trimmedCrop && (
            <div className="mt-4 text-center">
              <Button
                variant="primary"
                size="lg"
                onClick={() => setImagesList([...imagesList, trimmedCrop])}
              >
                Incluir Seleção
              </Button>
            </div>
          )}
        </div>
      )}
      {imagesList.length > 0 && (
        <>
          <hr />
          <div className="mt-3">
            <h4>Imagens Selecionadas:</h4>
            <Row className="mt-2 mb-2">
              {imagesList.map((image, index) => (
                <Col lg={3} sm={4} key={index}>
                  <Image src={image.toDataURL("image/jpeg")} thumbnail />
                  <div>
                    <Button
                      variant="danger"
                      size="sm"
                      disabled={loading}
                      onClick={() => {
                        setImagesList([
                          ...imagesList.filter((img, idx) => idx !== index),
                        ]);
                      }}
                    >
                      remover
                    </Button>
                  </div>
                </Col>
              ))}
            </Row>
            <hr />
            <Row className="mt-2">
              <Col>
                <Button
                  variant="primary"
                  size="lg"
                  disabled={loading}
                  onClick={saveImages}
                >
                  Salvar Imagens
                </Button>
                {loading && <FontAwesomeIcon icon={faSpinner} size="2x" spin />}
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default Uploader;
