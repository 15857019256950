import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";
import ReactDataGrid, { Column, SortDirection } from "react-data-grid";
import "react-data-grid/dist/react-data-grid.css";
import { Redirect, useParams } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import api from "../../shared/api";
import { Color, Project } from "../../shared/interfaces";
import "../ItemDetails/styles.css";

const ProjectDetails: React.FC = () => {
  const [sortData, setSortData] = useState<{
    column: string;
    direction: SortDirection;
  }>({
    column: "code",
    direction: "NONE",
  });
  const [projectData, setProjectData] = useState<Project>({} as Project);
  const [loading, setLoading] = useState(false);
  const [allColors, setAllColors] = useState<Color[]>([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { id } = useParams<{ id: any }>();
  const loadProject = () => {
    setLoading(true);
    let colorList: Color[] = [];
    let filteredColors: { [key: string]: Color } = {};
    api.get<Project>(`/projects/${id}`).then((res) => {
      const project = { ...res.data };
      if (project.items) {
        for (let i in project.items) {
          project.items[i].convertedImage =
            "data:image/jpeg;base64," +
            Buffer.from(project.items[i].imageData.data).toString("base64");
          if (project.items[i].colors !== undefined) {
            colorList = colorList.concat(project.items[i].colors!);
            let totalColorArea = 0;
            project.items[i].colors?.forEach((color) => {
              if (filteredColors[color.hex] === undefined) {
                filteredColors[color.hex] = { ...color, fullColorArea: 0 };
              }
              const colorTotalArea = +(color.fullColorArea || 0).toFixed(3);
              if (!filteredColors[color.hex].fullColorArea)
                filteredColors[color.hex].fullColorArea = 0;
              filteredColors[color.hex].fullColorArea! += colorTotalArea;
              totalColorArea += colorTotalArea;
            });
            project.items[i].totalArea = totalColorArea;
          }
        }
      }
      setAllColors(Object.values(filteredColors));
      setProjectData(project);
      setLoading(false);
    });
  };

  const getTotalColorArea = () => {
    let total = 0;
    allColors.forEach((color) => {
      total += color.fullColorArea || 0;
    });
    return total;
  };

  const exportCsvData = () => {
    const csvColors: {
      "Área Total": number;
      Código: string;
      Categoria: string;
      Referência: string;
      Cor: string;
      Textura: string;
      Tonalidade: string;
    }[] = [];
    allColors.forEach((color) => {
      csvColors.push({
        "Área Total": +(color.fullColorArea ?? 0).toFixed(3),
        Código: color.code,
        Categoria: color.category,
        Referência: color.ref ?? "-",
        Cor: color.name,
        Textura: color.texture ?? "-",
        Tonalidade: color.hue,
      });
    });
    const workSheet = XLSX.utils.json_to_sheet(csvColors);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    const data = new Blob([buffer], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, `${projectData.description}.xlsx`);
  };

  interface SummaryColor {
    fullColorArea: number;
  }

  const columns: Column<Color, SummaryColor>[] = [
    {
      key: "hex",
      name: "",
      width: 20,
      cellClass: "text-center",
      summaryFormatter() {
        return <strong>Total</strong>;
      },
      formatter(props) {
        return (
          <i
            className="item-color-badge"
            style={{ backgroundColor: props.row.hex }}
          ></i>
        );
      },
    },
    {
      key: "fullColorArea",
      name: "Área Total",
      summaryFormatter(props) {
        return (
          <>{`${props.row.fullColorArea.toLocaleString("pt-BR", {
            maximumFractionDigits: 3,
          })}m²`}</>
        );
      },
      formatter(props) {
        return (
          <>{`${props.row.fullColorArea?.toLocaleString("pt-BR", {
            maximumFractionDigits: 3,
          })}m²`}</>
        );
      },
    },
    { key: "code", name: "Código" },
    { key: "category", name: "Categoria" },
    { key: "ref", name: "Referência" },
    { key: "name", name: "Cor" },
    { key: "texture", name: "Textura" },
    { key: "hue", name: "Tonalidade" },
  ];
  const handleSort = useCallback(
    (columnKey: string, direction: SortDirection) => {
      setSortData({ column: columnKey, direction });
    },
    []
  );

  const getSortedRows = () => {
    if (sortData.direction === "NONE") return allColors;
    let sortedRows = [...allColors];
    console.log(sortData.column);
    switch (sortData.column) {
      case "code":
      case "fullColorArea":
        sortedRows = sortedRows.sort(
          (a, b) => a[sortData.column] - b[sortData.column]
        );
        break;
      default:
        sortedRows = sortedRows.sort((a, b) => {
          if (
            a[sortData.column].toString().toLowerCase() >
            b[sortData.column].toString().toLowerCase()
          )
            return 1;
          if (
            b[sortData.column].toString().toLowerCase() >
            a[sortData.column].toString().toLowerCase()
          )
            return -1;
          return 0;
        });
    }
    return sortData.direction === "DESC" ? sortedRows.reverse() : sortedRows;
  };

  useEffect(() => {
    loadProject();
  }, []);

  if (projectData.hide) return <Redirect to="/projetos" />;

  return (
    <>
      <h2>
        Detalhes do Projeto {projectData.description}
        <LinkContainer to={`/projetos/${id}/item/novo`}>
          <Button className="float-right" variant="primary">
            <FontAwesomeIcon icon={faPlus} /> Novo Item
          </Button>
        </LinkContainer>
        <Button
          className="mr-2 float-right"
          variant="warning"
          onClick={() => setConfirmDelete(true)}
        >
          Arquivar Projeto
        </Button>
        <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Exclusão de Cor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Deseja realmente arquivar este projeto?
            <br />
            <strong>Atenção: esta ação não poderá ser desfeita!</strong>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
              Cancelar
            </Button>
            <Button
              variant="warning"
              onClick={() => {
                setConfirmDelete(false);
                setLoading(true);
                api.post(`/projects/${projectData._id}/archive`).then((res) => {
                  loadProject();
                });
              }}
            >
              Arquivar
            </Button>
          </Modal.Footer>
        </Modal>
      </h2>
      <h4>{projectData.description}</h4>
      <hr />
      {loading && <FontAwesomeIcon icon={faSpinner} size="2x" spin />}
      <Row className="mt-2">
        {projectData.items && projectData.items.length > 0 && (
          <>
            <Col className="mt-3">
              <h4>Vitrais neste projeto:</h4>
              <Row className="mt-2 mb-5">
                {projectData.items.map((image, index) => (
                  <Col lg={4} key={index} className="text-center mb-3">
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xs={3}>
                            <Link to={`/projetos/${id}/item/${image._id}`}>
                              <Image src={image.convertedImage} thumbnail />
                            </Link>
                          </Col>
                          <Col xs={9} className="text-left">
                            <div>
                              <strong>Número da OP:</strong> {image.number}
                            </div>
                            <div className="mt-2">
                              <div>
                                <strong>Cores Utilizadas:</strong>
                              </div>
                              {image.colors?.map((color) => (
                                <i
                                  className="item-color-badge mr-1"
                                  style={{ backgroundColor: color.hex }}
                                ></i>
                              ))}
                            </div>
                            <div className="mt-2">
                              <strong>Área Total: </strong>
                              {image.totalArea?.toLocaleString("pt-BR", {
                                maximumFractionDigits: 3,
                              })}
                              m²
                            </div>
                            <div className="mt-4">
                              <Link to={`/projetos/${id}/item/${image._id}`}>
                                <Button variant="primary" size="sm">Ver Detalhes</Button>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
              <hr />
              <Row className="mt-5">
                <h4>Cores Utilizadas:</h4>
                <Button
                  variant="primary"
                  className="ml-5"
                  onClick={exportCsvData}
                >
                  Exportar Excel
                </Button>
              </Row>
              <Row className="mt-5">
                <Col>
                  <ReactDataGrid
                    columns={columns}
                    rows={getSortedRows()}
                    defaultColumnOptions={{
                      sortable: true,
                    }}
                    sortColumn={sortData.column}
                    sortDirection={sortData.direction}
                    onSort={handleSort}
                    summaryRows={[
                      {
                        fullColorArea: getTotalColorArea(),
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </>
        )}
        {!projectData.items ||
          (projectData.items.length === 0 && (
            <Col className="mt-4">
              <h5>
                Este projeto ainda não possui OPs, adicione uma clicando no
                botão acima.
              </h5>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default ProjectDetails;
