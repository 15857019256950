interface CanvasBounds {
  top: number | null;
  left: number | null;
  right: number | null;
  bottom: number | null;
}

const trimCanvas = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext("2d");
  if (!ctx) return canvas;
  const trimmed = document.createElement("canvas").getContext("2d");
  if (!trimmed) return canvas;
  const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const length = pixels.data.length - 4;
  const bounds: CanvasBounds = {
    top: null,
    left: null,
    right: null,
    bottom: null,
  };
  let x;
  let y;

  for (let i = 0; i < length; i += 4) {
    if (
      pixels.data[i] !== 255 &&
      pixels.data[i + 1] !== 255 &&
      pixels.data[i + 2] !== 255
    ) {
      x = (i / 4) % canvas.width;
      y = ~~(i / 4 / canvas.width);

      if (bounds.top === null) bounds.top = y;
      if (bounds.bottom === null || bounds.bottom < y) bounds.bottom = y;
      if (bounds.left === null || bounds.left > x) bounds.left = x;
      if (bounds.right === null || bounds.right < x) bounds.right = x;
    }
  }
  const trimHeight = bounds.bottom! - bounds.top!;
  const trimWidth = bounds.right! - bounds.left!;
  if (trimWidth === 0 || trimHeight === 0) return canvas;

  const trimmedData = ctx.getImageData(
    bounds.left!,
    bounds.top!,
    trimWidth,
    trimHeight
  );

  trimmed.canvas.width = trimWidth;
  trimmed.canvas.height = trimHeight;
  trimmed.putImageData(trimmedData, 0, 0);
  return trimmed.canvas;
};

export default trimCanvas;
