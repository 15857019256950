import {
  faCog,
  faEye,
  faPlus,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import api from "../../shared/api";
import { Project } from "../../shared/interfaces";

const Projects: React.FC = () => {
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [addingError, setAddingError] = useState(false);
  const [newProject, setNewProject] = useState<Project>({} as Project);

  const loadProjects = () => {
    setLoading(true);
    setProjectList([]);
    api.get("/projects").then((res) => {
      setProjectList(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadProjects();
  }, []);

  return (
    <>
      <h2>
        Projetos
        <Button
          className="float-right"
          variant="primary"
          onClick={() => setAdding(true)}
        >
          <FontAwesomeIcon icon={faPlus} /> Novo Projeto
        </Button>
        <Modal show={adding} onHide={() => setAdding(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Inclusão de Projeto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nome:</Form.Label>
              <Form.Control
                type="text"
                value={newProject.description}
                required
                onChange={(e) =>
                  setNewProject({
                    ...newProject,
                    description: e.target.value.toString(),
                  })
                }
              />
            </Form.Group>
            {addingError && (
              <Alert
                variant="danger"
                dismissible
                onClose={() => setAddingError(false)}
              >
                <strong>Atenção:</strong> Dados de OP duplicados ou inválidos.
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setAdding(false)}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setLoading(true);
                api.post("/projects", newProject).then(
                  (res) => {
                    setNewProject({} as Project);
                    setAdding(false);
                    loadProjects();
                  },
                  (res) => {
                    setLoading(false);
                    setAddingError(true);
                  }
                );
              }}
            >
              Adicionar
            </Button>
          </Modal.Footer>
        </Modal>
      </h2>
      <hr />
      {loading && <FontAwesomeIcon icon={faSpinner} size="2x" spin />}
      {projectList.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nome</th>
              <th className="text-center">
                <FontAwesomeIcon icon={faCog} />
              </th>
            </tr>
          </thead>
          <tbody>
            {projectList.map((project, index) => (
              <tr key={index}>
                <td>{project.description}</td>
                <td className="text-center">
                  <Link to={`/projetos/${project._id}`}>
                    <Button variant="primary" size="sm">
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Projects;
