import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { IAuth } from "../../shared/interfaces";

interface LoginPageProps {
  auth: IAuth;
  setAuth: Function;
  storageKey: string;
  location?: any;
}

const Login: React.FC<LoginPageProps> = (props) => {
  const { auth, setAuth, storageKey } = props;

  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    const current = localStorage.getItem(storageKey);
    if (current) {
      const currentData = JSON.parse(current) as IAuth;
      setAuth({ ...auth, isAuthenticated: currentData.isAuthenticated });
    }
  }, []);

  function doLogin(e: React.FormEvent) {
    e.preventDefault();
    setErrorMessage(false);
    if (password === "vitrais") {
      setAuth({ ...auth, isAuthenticated: true });
      localStorage.setItem(
        storageKey,
        JSON.stringify({ ...auth, isAuthenticated: true })
      );
    } else {
      setErrorMessage(true);
    }
  }

  return auth.isAuthenticated ? (
    <Redirect to={props.location?.state?.from.pathname || "/"} />
  ) : (
    <div className="mt-5">
      <h2>Login</h2>
      {errorMessage && (
        <Alert
          variant="danger"
          dismissible
          onClose={() => setErrorMessage(false)}
        >
          <strong>Atenção:</strong> Senha incorreta.
        </Alert>
      )}
      <Form className="mt-5 mx-auto col-3" onSubmit={doLogin}>
        <Form.Group controlId="login">
          <Form.Label>Login:</Form.Label>
          <Form.Control type="text" disabled value="vitrais" />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Senha:</Form.Label>
          <Form.Control
            type="password"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value.toString())}
          />
        </Form.Group>
        <Form.Group>
          <Button type="submit" variant="primary">
            Acessar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default Login;
