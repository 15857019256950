import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import Projects from "./pages/Projects";
import ProjectDetails from "./pages/ProjectDetails";
import ItemDetails from "./pages/ItemDetails";
import Uploader from "./pages/Uploader";
import Colors from "./pages/Colors";
import { IAuth } from "./shared/interfaces";

export interface AppRouter {
  authData: IAuth;
  setAuthData: Function;
  storageKey: string;
}

export default function Routes(props: AppRouter) {
  return (
    <Switch>
      <Route
        path="/login"
        render={(rProps) => (
          <Login
            auth={props.authData}
            setAuth={props.setAuthData}
            storageKey={props.storageKey}
            {...rProps}
          />
        )}
      />
      <PrivateRoute
        path="/projetos"
        exact
        component={Projects}
        isAuthenticated={props.authData.isAuthenticated}
      />
      <PrivateRoute
        path="/projetos/:id"
        exact
        component={ProjectDetails}
        isAuthenticated={props.authData.isAuthenticated}
      />
      <PrivateRoute
        path="/projetos/:id/item/novo"
        exact
        component={Uploader}
        isAuthenticated={props.authData.isAuthenticated}
      />
      <PrivateRoute
        path="/projetos/:id/item/:imageid"
        exact
        component={ItemDetails}
        isAuthenticated={props.authData.isAuthenticated}
      />
      <PrivateRoute
        path="/cores"
        component={Colors}
        isAuthenticated={props.authData.isAuthenticated}
      />
      <Route path="*">
        <Redirect to="/projetos" />
      </Route>
    </Switch>
  );
}
