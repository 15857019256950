import {
  faPencilAlt,
  faSpinner,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { CompactPicker } from "react-color";
import api from "../../shared/api";
import { Color } from "../../shared/interfaces";

const ColorItem: React.FC<{ color: Color; loadColors: Function }> = (props) => {
  const { color, loadColors } = props;
  const [newColor, setNewColor] = useState({ ...color });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  return (
    <tr>
      <td>
        <span
          className="colorBadge"
          style={{ backgroundColor: color.hex }}
        ></span>
      </td>
      <td>{color.code}</td>
      <td>{color.category}</td>
      <td>{color.ref || "---"}</td>
      <td>{color.name}</td>
      <td>{color.texture || "---"}</td>
      <td>{color.hue}</td>
      <td>{color.hex}</td>
      <td className="text-center">
        <Button variant="info" size="sm" onClick={() => setEdit(true)}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </Button>
        <Modal show={edit} onHide={() => setEdit(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Alteração de Cor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Código:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.code}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        code: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Categoria:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.category}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        category: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Referência:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.ref}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        ref: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Cor:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.name}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        name: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Textura:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.texture}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        texture: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Tonalidade:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.hue}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        hue: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mt-3">
                  <CompactPicker
                    color={newColor.hex}
                    onChangeComplete={(colorP) =>
                      setNewColor({
                        ...newColor,
                        hex: colorP.hex,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setEdit(false)}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setLoading(true);
                api.put(`/colors/${color._id}`, newColor).then((res) => {
                  loadColors();
                });
              }}
            >
              Salvar Alterações
            </Button>
          </Modal.Footer>
        </Modal>
        <Button
          className="ml-2"
          variant="danger"
          size="sm"
          onClick={() => setConfirmDelete(true)}
          disabled={loading}
        >
          {!loading && <FontAwesomeIcon icon={faTrash} />}
          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
        </Button>
        <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Exclusão de Cor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Deseja realmente remover esta cor?
            <br />
            <strong>Atenção: esta ação não poderá ser desfeita!</strong>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
              Cancelar
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setConfirmDelete(false);
                setLoading(true);
                api.delete(`/colors/${color._id}`).then((res) => {
                  loadColors();
                });
              }}
            >
              Excluir
            </Button>
          </Modal.Footer>
        </Modal>
      </td>
    </tr>
  );
};

export default ColorItem;
