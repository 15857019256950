declare global {
  interface Window {
    pdfjsLib: any;
  }
}
export default async function convertPdf(data: string) {
  const PDFJS = window.pdfjsLib;
  const pdf = await PDFJS.getDocument({ data }).promise;
  const page = await pdf.getPage(1);
  const canvas = document.createElement("canvas");
  const renderContext = {
    canvasContext: canvas.getContext("2d")!,
    viewport: page.getViewport({ scale: 5 }),
  };

  canvas.width = renderContext.viewport.width;
  canvas.height = renderContext.viewport.height;

  await page.render(renderContext).promise;

  const pageCanvas = document.createElement("canvas");
  const pageCtx = pageCanvas.getContext("2d")!;
  pageCanvas.width = canvas.width;
  pageCanvas.height = canvas.height;
  pageCtx.putImageData(
    renderContext.canvasContext.getImageData(
      0,
      0,
      pageCanvas.width,
      pageCanvas.height
    ),
    0,
    0
  );
  return pageCanvas.toDataURL("image/png");
}
