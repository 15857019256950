import { faCog, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { CompactPicker } from "react-color";
import api from "../../shared/api";
import { Color } from "../../shared/interfaces";
import ColorItem from "./ColorItem";
import "./styles.css";

const Colors: React.FC = () => {
  const [colorList, setColorList] = useState<Color[]>([]);
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [newColor, setNewColor] = useState<Color>({} as Color);

  const loadColors = () => {
    setLoading(true);
    setColorList([]);
    api.get("/colors").then((res) => {
      setColorList(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadColors();
  }, []);

  return (
    <>
      <h2>
        Cores
        <Button
          className="float-right"
          variant="primary"
          onClick={() => setAdding(true)}
        >
          <FontAwesomeIcon icon={faPlus} /> Nova Cor
        </Button>
        <Modal show={adding} onHide={() => setAdding(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Inclusão de Cor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Código:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.code}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        code: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Categoria:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.category}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        category: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Referência:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.ref}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        ref: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Cor:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.name}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        name: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Textura:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.texture}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        texture: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Tonalidade:</Form.Label>
                  <Form.Control
                    type="text"
                    value={newColor.hue}
                    required
                    onChange={(e) =>
                      setNewColor({
                        ...newColor,
                        hue: e.target.value.toString(),
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mt-3">
                  <CompactPicker
                    color={newColor.hex}
                    onChangeComplete={(color) =>
                      setNewColor({
                        ...newColor,
                        hex: color.hex,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setAdding(false)}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setLoading(true);
                api
                  .post("/colors", {
                    list: [newColor],
                  })
                  .then((res) => {
                    setNewColor({} as Color);
                    setAdding(false);
                    loadColors();
                  });
              }}
            >
              Adicionar
            </Button>
          </Modal.Footer>
        </Modal>
      </h2>
      <hr />
      {loading && <FontAwesomeIcon icon={faSpinner} size="2x" spin />}
      {colorList.length > 0 && (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Código</th>
              <th>Categoria</th>
              <th>Referência</th>
              <th>Cor</th>
              <th>Textura</th>
              <th>Tonalidade</th>
              <th>Cód. Hexa</th>
              <th className="text-center">
                <FontAwesomeIcon icon={faCog} />
              </th>
            </tr>
          </thead>
          <tbody>
            {colorList.map((color, index) => (
              <ColorItem key={index} color={color} loadColors={loadColors} />
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Colors;
