import { faChevronLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  Image,
  InputGroup,
  Row
} from "react-bootstrap";
import ReactDataGrid, { Column, SortDirection } from "react-data-grid";
import "react-data-grid/dist/react-data-grid.css";
import { useParams } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import XLSX from "xlsx";
import api from "../../shared/api";
import { Color, Image as ImageData } from "../../shared/interfaces";
import processColors from "../../shared/processColors";
import ColorInfo from "./ColorInfo";

const ItemDetails: React.FC = () => {
  const [sortData, setSortData] = useState<{
    column: string;
    direction: SortDirection;
  }>({
    column: "code",
    direction: "NONE",
  });
  const [imageData, setImageData] = useState<ImageData>({} as ImageData);
  const [loading, setLoading] = useState(false);
  const [cardsVision, setCardsVision] = useState(false);
  const [defaultColors, setDefaultColors] = useState<Color[]>([] as Color[]);
  const [defaultCat, setDefaultCat] = useState<string[]>([]);
  const [selectedCats, setSelectedCats] = useState<string[]>([]);
  const [sensibility, setSensibility] = useState(0.005);
  const { id, imageid } = useParams<{ id: any; imageid: any }>();
  const area = (imageData.width || 0) * (imageData.height || 0);
  const loadDetails = () => {
    setLoading(true);
    api.get<ImageData>(`/image/${imageid}`).then((res) => {
      const img = document.createElement("img");
      const imgData = {
        ...res.data,
        convertedImage:
          "data:image/jpeg;base64," +
          Buffer.from(res.data.imageData.data).toString("base64"),
      };
      img.onload = () => {
        imgData.pxWidth = img.naturalWidth;
        imgData.pxHeight = img.naturalHeight;
        setImageData(imgData);
        api.get(`/colors`).then((resC) => {
          setDefaultColors(resC.data);
          let defaultCats: string[] = [];
          (resC.data as Color[]).forEach((color) => {
            if (defaultCats.indexOf(color.category) === -1) {
              defaultCats.push(color.category);
            }
          });
          setDefaultCat(defaultCats);
          setSelectedCats(defaultCats);
          setLoading(false);
        });
      };
      img.src = imgData.convertedImage;
    });
  };

  const getTotalColorArea = () => {
    let total = 0;
    imageData.colors?.forEach((color) => {
      total += color.fullColorArea || 0;
    });
    return total;
  };

  const createDataLabel = (text: string, value: number | string | undefined) => {
    const wrapper = document.createElement("h6");
    const label = document.createElement("strong");
    label.textContent = text;
    const data = document.createTextNode(` ${value}m²`);
    wrapper.appendChild(label);
    wrapper.appendChild(data);
    return wrapper;
  };

  interface SummaryColor {
    fullColorArea: number;
  }

  const columns: Column<Color, SummaryColor>[] = [
    {
      key: "hex",
      name: "",
      width: 20,
      cellClass: "text-center",
      summaryFormatter() {
        return <strong>Total</strong>;
      },
      formatter(props) {
        return (
          <i
            className="item-color-badge"
            style={{ backgroundColor: props.row.hex }}
          ></i>
        );
      },
    },
    {
      key: "fullColorArea",
      name: "Área",
      width: 180,
      summaryFormatter(props) {
        return (
          <>{`${props.row.fullColorArea.toLocaleString("pt-BR", {
            maximumFractionDigits: 3,
          })}m²`}</>
        );
      },
      formatter(props) {
        return (
          <>{`${(props.row.colorArea || 0).toLocaleString("pt-BR", {
            maximumFractionDigits: 3,
          })}m² x${imageData.quantity} → ${(
            props.row.fullColorArea || 0
          ).toLocaleString("pt-BR", {
            maximumFractionDigits: 3,
          })}m²`}</>
        );
      },
    },
    { key: "code", name: "Código" },
    { key: "category", name: "Categoria" },
    { key: "ref", name: "Referência" },
    { key: "name", name: "Cor" },
    { key: "texture", name: "Textura" },
    { key: "hue", name: "Tonalidade" },
  ];

  const handleSort = useCallback(
    (columnKey: string, direction: SortDirection) => {
      setSortData({ column: columnKey, direction });
    },
    []
  );

  const getSortedRows = () => {
    if (sortData.direction === "NONE") return imageData.colors || [];
    let sortedRows = [...(imageData.colors || [])];
    switch (sortData.column) {
      case "code":
      case "fullColorArea":
        sortedRows = sortedRows.sort(
          (a, b) => a[sortData.column] - b[sortData.column]
        );
        break;
      default:
        sortedRows = sortedRows.sort((a, b) => {
          if (
            a[sortData.column].toString().toLowerCase() >
            b[sortData.column].toString().toLowerCase()
          )
            return 1;
          if (
            b[sortData.column].toString().toLowerCase() >
            a[sortData.column].toString().toLowerCase()
          )
            return -1;
          return 0;
        });
    }
    return sortData.direction === "DESC" ? sortedRows.reverse() : sortedRows;
  };

  const exportPDF = () => {
    const el = document.getElementById("detalhes-vitral");
    if (!el) return;
    html2canvas(el, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      logging: false,
      onclone: (document) => {
        const data = document.querySelectorAll("div.replace-for-data");
        data[0].replaceWith(createDataLabel("OP:", imageData.number));
        data[1].replaceWith(createDataLabel("Largura:", imageData.width));
        data[2].replaceWith(createDataLabel("Altura:", imageData.height));
        data[3].replaceWith(createDataLabel("Quantidade:", imageData.quantity));

        const removeStyle = document.querySelector("div.remove-style");
        removeStyle?.setAttribute("style", "");
      },
    }).then((canvas) => {
      const pdf = new jsPDF({
        unit: "px",
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(
        canvas,
        "JPEG",
        0,
        0,
        canvas.width * 0.6,
        canvas.height * 0.6,
        "bg",
        "NONE"
      );
      pdf.save("vitral.pdf");
    });
  };

  const exportCsvData = () => {
    const csvColors: {
      "Área Total": number;
      Código: string;
      Categoria: string;
      Referência: string;
      Cor: string;
      Textura: string;
      Tonalidade: string;
    }[] = [];
    imageData.colors?.forEach((color) => {
      csvColors.push({
        "Área Total": +(color.fullColorArea ?? 0).toFixed(3),
        Código: color.code,
        Categoria: color.category,
        Referência: color.ref ?? "-",
        Cor: color.name,
        Textura: color.texture ?? "-",
        Tonalidade: color.hue,
      });
    });
    const workSheet = XLSX.utils.json_to_sheet(csvColors);
    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    const data = new Blob([buffer], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, `Vitral.xlsx`);
  };

  useEffect(() => {
    loadDetails();
  }, []);

  const handleWidthChange = (newWidth: number) => {
    const ratio = (imageData.pxWidth || 0) / (imageData.pxHeight || 1);
    setImageData({
      ...imageData,
      width: newWidth,
      height: +(newWidth / ratio).toFixed(1),
    });
  };

  const handleQuantityChange = (newQuantity: number) => {
    const colors = [...imageData.colors!];
    colors.forEach((color) => {
      color.colorArea = (color.count / imageData.totalPixels!) * area;
      color.fullColorArea = color.colorArea * newQuantity;
    });
    setImageData({
      ...imageData,
      quantity: newQuantity,
    });
  };

  const processImageColors = async (fixedColors: Color[] | undefined) => {
    setLoading(true);
    if (imageData.convertedImage) {
      let selectedColors = defaultColors.filter(
        (color) => selectedCats.indexOf(color.category) > -1
      );
      let processSensibility = sensibility;
      if (fixedColors !== undefined) {
        selectedColors = fixedColors;
        processSensibility = 0;
      }
      const colors = await processColors(
        imageData.convertedImage,
        selectedColors,
        processSensibility
      );
      let totalPixels = 0;
      colors.forEach((color) => {
        totalPixels += color.count;
      });
      colors.forEach((color) => {
        color.colorArea = (color.count / totalPixels) * area;
        color.fullColorArea = color.colorArea * imageData.quantity;
      });
      setImageData({ ...imageData, colors, totalPixels });
      await api.put(`/image/${imageid}`, {
        colors,
        totalPixels,
        width: imageData.width || 0,
        height: imageData.height || 0,
        quantity: imageData.quantity || 0,
        number: imageData.number
      });
    }
    setLoading(false);
  };

  const saveDimensions = async () => {
    setLoading(true);
    await api.put(`/image/${imageid}`, {
      number: imageData.number,
      width: imageData.width || 0,
      height: imageData.height || 0,
      quantity: imageData.quantity || 0,
    });
    setLoading(false);
  };

  return (
    <>
      <h2>
        Detalhes do Vitral
        <LinkContainer to={`/projetos/${id}`}>
          <Button className="float-right" variant="primary">
            <FontAwesomeIcon icon={faChevronLeft} /> Voltar
          </Button>
        </LinkContainer>
      </h2>
      <hr />
      {loading && <FontAwesomeIcon icon={faSpinner} size="2x" spin />}
      <Card className="mt-4" id="detalhes-vitral">
        <Card.Body>
          <Row>
            <Col sm={2}>
              <Image src={imageData.convertedImage} fluid />
            </Col>
            <Col sm={10}>
              <h4 className="mb-4">
                Dimensões
                <small>
                  (
                  {`${area.toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                  })}m² x${imageData.quantity || 0} → ${(
                    area * imageData.quantity || 0
                  ).toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                  })}m²`}
                  )
                </small>
                :
              </h4>
              <Row>
                <Col>
                  <InputGroup className="mb-3 replace-for-data">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Número da OP:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="text"
                      value={imageData.number}
                      onChange={(e) => {
                        setImageData({ ...imageData, number: e.target.value });
                      }}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3 replace-for-data">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Largura:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="number"
                      step="0.1"
                      value={imageData.width}
                      onChange={(e) => {
                        isNaN(+e.target.value)
                          ? handleWidthChange(0)
                          : handleWidthChange(+e.target.value);
                      }}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3 replace-for-data">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Altura:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="number"
                      step="0.1"
                      value={imageData.height}
                      onChange={(e) => {
                        isNaN(+e.target.value)
                          ? setImageData({ ...imageData, height: 0 })
                          : setImageData({
                              ...imageData,
                              height: +e.target.value,
                            });
                      }}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3 replace-for-data">
                    <InputGroup.Prepend>
                      <InputGroup.Text>Quantidade:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="number"
                      step="1"
                      value={imageData.quantity || 0}
                      onChange={(e) => {
                        isNaN(+e.target.value)
                          ? handleQuantityChange(0)
                          : handleQuantityChange(+e.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col data-html2canvas-ignore>
                  <Row>
                    <Button variant="primary" onClick={saveDimensions}>
                      Salvar Alterações
                    </Button>
                  </Row>
                  <Row className="mt-3">
                    {!cardsVision && (
                      <Button
                        variant="outline-primary"
                        onClick={() => setCardsVision(true)}
                      >
                        Exibir Cards
                      </Button>
                    )}
                    {cardsVision && (
                      <Button
                        variant="outline-primary"
                        onClick={() => setCardsVision(false)}
                      >
                        Exibir Tabela
                      </Button>
                    )}
                  </Row>
                  <Row className="mt-3">
                    <Button variant="outline-primary" onClick={exportCsvData}>
                      Exportar Excel
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <div>
                <h4 className="mt-3">
                  Cores:
                  {cardsVision && (
                    <Button
                      variant="outline-primary"
                      className="float-right"
                      onClick={exportPDF}
                      data-html2canvas-ignore
                    >
                      Exportar PDF
                    </Button>
                  )}
                </h4>
              </div>
              {!cardsVision && (
                <Row data-html2canvas-ignore>
                  {!!imageData.colors?.length && (
                    <Col>
                      <ReactDataGrid
                        columns={columns}
                        rows={getSortedRows()}
                        defaultColumnOptions={{
                          sortable: true,
                        }}
                        sortColumn={sortData.column}
                        sortDirection={sortData.direction}
                        onSort={handleSort}
                        summaryRows={[
                          {
                            fullColorArea: getTotalColorArea(),
                          },
                        ]}
                      />
                    </Col>
                  )}
                  {!imageData.colors?.length && (
                    <Col className="text-center mt-5" data-html2canvas-ignore>
                      <h4>Nenhuma cor encontrada.</h4>
                    </Col>
                  )}
                </Row>
              )}
              {cardsVision && (
                <Row className="mt-3">
                  {imageData.colors?.length &&
                    imageData.colors?.map((color) => (
                      <ColorInfo
                        color={color}
                        quantity={imageData.quantity}
                        removeCallback={() => {
                          const newColors = imageData.colors?.filter(
                            (dColor) => dColor.code !== color.code
                          );
                          processImageColors(newColors);
                        }}
                      />
                    ))}
                </Row>
              )}
            </Col>
          </Row>
          <hr data-html2canvas-ignore />
          <Row data-html2canvas-ignore>
            <Col>
              <h4 className="mt-3">Processar Cores:</h4>
              <div className="mt-4 mb-4">
                <h5>Categorias Desejadas:</h5>
                {defaultCat.map((category) => (
                  <Form.Check
                    inline
                    label={category}
                    key={`inline-${category}`}
                    defaultChecked
                    onChange={(e) => {
                      const newSelection = [...selectedCats];
                      if (e.target.checked) {
                        newSelection.push(category);
                      } else {
                        newSelection.splice(newSelection.indexOf(category), 1);
                      }
                      setSelectedCats(newSelection);
                    }}
                  />
                ))}
              </div>
              <div className="mb-4">
                <h5>Sensibilidade:</h5>
                <Form.Control
                  type="range"
                  min={0.001}
                  max={0.019}
                  step={0.002}
                  value={sensibility}
                  onChange={(e) => {
                    setSensibility(+e.target.value);
                  }}
                  style={{ maxWidth: "350px" }}
                />
              </div>
              <Button
                className="mt-2"
                variant="outline-primary"
                onClick={() => processImageColors(undefined)}
                disabled={loading}
              >
                Processar Cores
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ItemDetails;
