import React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
}

export default function PrivateRoute(privateProps: PrivateRouteProps) {
  const { isAuthenticated, component, render, ...otherProps } = privateProps;
  return (
    <Route
      render={(props: RouteComponentProps) => {
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: privateProps.location },
              }}
            />
          );
        }

        if (component) {
          return React.createElement(component);
        }

        if (render) {
          return render(props);
        }
      }}
      {...otherProps}
    />
  );
}
